// Use This URL for testing
const URL = "kolhapur.mvconsultants.org";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;

export const SRF_ID_BASE_NABL = 1;
export const SRF_ID_BASE_IAS = 1;
export const SRF_ID_BASE_NONABL = 1;

export const INVOICE_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "ICICI Bank, Plot No. 1, S.No. 15/1, Sartak Terrace, Kothrud,",
  "Bank A/c. No.": "033805002521",
  "IFSC Code": "ICIC0000338",
};

export const weightUncertaintySupportiveValues ={
  barometer: 2.4,
  tempIndicator:0.58,
  rhIndicator:1.74,
  weightBox:7950,
};

export const EccentricityDiagramInstruemntID = 0;
export const G_Value = 9.80665;

export const GST_NUMBER = "27ADXPN2758E1Z0";

export const DISABLED_MODULE =["Courier Register","Expenses","Master In/Out","Client Complaints","Client Feedback","Audit","Document Upload","User","Purchase"
,"DUC Master","Department","Request Form","Attendance","MarkAttendance","CoordinatesAddress","Settings","Datasheet Template","Discipline","OfflineMode","WCR Report","Department Analytics","Supplier Invoice","Training Resources","Inward Register","SRF_ULR_Register","External Calibration"]; 

export const DEVICE_TYPE = ["desktop", "tablet", "mobile"];

export const LATEST_HASH = "89e09b5893d3f6da022ade0dbd903a52d47c63c6";

export const BackDateShow = true;